import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Flex,
    Grid,
    Heading,
    Link,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { ViewAdvertModel } from 'components/view-advert/ViewAdvertModel';
import { useAuth } from 'contexts/AuthContext';
import companyApiInstance from 'core/api/company.api';
import { Company } from 'core/models/company.model';
import { Vacancy } from 'core/models/vacancy.model';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ApplyModel } from './ApplyModel';

export interface PathsParamsProps {
    companyId: string;
}

export function CompanyPage() {
    const params = useParams<PathsParamsProps>();
    const [shownFlyer, setShownFlyer] = useState(Math.random() > 0.5);
    const auth = useAuth();

    const { data: company } = useQuery<Company>(['company', params.companyId], () =>
        auth.userType === 'student'
            ? companyApiInstance.getCompanyForStudent(params.companyId).then((res) => res.data)
            : companyApiInstance.getCompany(params.companyId).then((res) => res.data)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setShownFlyer((e) => !e);
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    return (
        !!company && (
            <Box pt={{ base: '130px', md: '80px', xl: '80px' }} pb={'20px'} px={3}>
                <Grid gridTemplateColumns={'1fr 1fr'} gap={6}>
                    <Box mb={4}>
                        <img src={company.logoURL} alt="" style={{ height: '4rem', marginBottom: '1rem' }} />
                        <Heading size="lg">{company.name}</Heading>
                        <Text size="sm" color={'gray.500'}>
                            {company.address}
                        </Text>
                        <Text my={2} whiteSpace="pre-line">
                            {company.description}
                        </Text>
                        {company.webURL && company.webURL.trim() ? (
                            <Link
                                color={'blue'}
                                href={
                                    company.webURL.startsWith('https://') ? company.webURL : `https://${company.webURL}`
                                }
                                isExternal
                            >
                                <Flex alignItems={'center'} my={2}>
                                    <ExternalLinkIcon mx="2px" />
                                    {company.webURL}
                                </Flex>
                            </Link>
                        ) : null}
                    </Box>
                    <Box mb={{ base: '0px', lg: '20px' }} alignItems="center">
                        {company.video.trim() ? (
                            <iframe
                                src={company.video}
                                style={{ width: '100%', aspectRatio: '16/9', border: '1px solid lightgray' }}
                                allow="autoplay"
                                title="company video"
                            ></iframe>
                        ) : null}
                    </Box>
                </Grid>

                <Grid gridTemplateColumns={'3fr 1fr'} gap={6}>
                    <Box>
                        <Heading size="md" mb={3}>
                            Available Vacancies
                        </Heading>
                        {auth.userType === 'student' || params.companyId === auth.company?._id ? (
                            <>
                                <TableContainer>
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>Job Title</Th>
                                                <Th>Advert</Th>
                                                <Th>Applicants</Th>
                                                <Th> </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {company.vacancies?.map((vacancy: Vacancy, index: number) => (
                                                <Tr key={vacancy._id}>
                                                    <Td>{index + 1}</Td>
                                                    <Td>{vacancy.jobTitle}</Td>
                                                    <Td>
                                                        <ViewAdvertModel vacancy={vacancy} />
                                                    </Td>
                                                    <Td>{vacancy.applications?.length}</Td>
                                                    <Td>
                                                        {auth.userType === 'student' ? (
                                                            <ApplyModel company={company} vacancy={vacancy} />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                {company.vacancies.length === 0 && (
                                    <Alert status="warning" borderRadius="5px" justifyContent="center">
                                        <AlertIcon />
                                        <AlertDescription>
                                            No Vacancies Available. Please Check Again Later
                                        </AlertDescription>
                                    </Alert>
                                )}
                            </>
                        ) : (
                            <>
                                <Alert status="warning" borderRadius="5px" justifyContent="center">
                                    <AlertIcon />
                                    <AlertDescription>Available only to the students</AlertDescription>
                                </Alert>
                            </>
                        )}
                    </Box>

                    <Box alignItems="center" position={'relative'}>
                        <img
                            src={company.flyer01}
                            alt=""
                            style={{
                                transition: 'all 1s ease-in',
                                zIndex: -100,
                                opacity: shownFlyer ? 0 : 1,
                            }}
                        />
                        <img
                            src={company.flyer02}
                            alt=""
                            style={{
                                top: 0,
                                zIndex: -10,
                                position: 'absolute',
                                transition: 'all 1s ease-in',
                                opacity: shownFlyer ? 1 : 0,
                            }}
                        />
                    </Box>
                </Grid>
            </Box>
        )
    );
}
