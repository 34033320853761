export enum UserRole {
    Unknow = 'unknow',
    Student = 'student',
    Company = 'company',
    Admin = 'admin',
}

export interface User {
    _id: string;
    available: boolean;
    indexNo: string;
    level: number;
    degree: string;
    linkedIn: string;
    personalSite: string;
    description: string;
    regNo: string;
    studentId: Student;
    cvList?: CVBase[];
    applications?: ApplicationBase[];
    profilePic: string;
}

export interface CVBase {
    cvName: string;
    url: string;
    studentId: string;
    lastUpdateAt: Date;
    createdAt: Date;
}

export interface ApplicationBase {
    vacancyId: string;
    studentId: string;
    companyId: string;
    cvId: string;
    lastUpdateAt: Date;
    createdAt: Date;
}

export interface Student {
    _id: string;
    email: string;
    name: string;
    role: string;
    userId: string;
    linkedIn: string;
    personalSite: string;
    description: string;
    profilePic: string;
}

export interface StudentUpdate {
    studentId: string;
    userId: string;
    name?: string;
    linkedIn?: string;
    personalSite?: string;
    description?: string;
    newPassword?: string;
}

export enum Degree {
    IS3 = 'BSc. Information Systems',
    IS4 = 'BSc. (Hons) Information Systems',
    CS3 = 'Bsc. Computer Science',
    CS4 = 'Bsc. (Hons) Computer Science',
    SE = 'Bsc. (Hons) Software Engineering',
}
