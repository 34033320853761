import { ArrowBackIcon, DownloadIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    CircularProgress,
    Heading,
    HStack,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ViewAdvertModel } from 'components/view-advert/ViewAdvertModel';
import { QueryKeys } from 'constant/queryKeys';
import { useAuth } from 'contexts/AuthContext';
import companyApi from 'core/api/company.api';
import vacancyApi from 'core/api/vacancy.api';
import { ApplicationBase } from 'core/models/user.model';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { VacancyForm } from './VacancyForm';

let JSZip = require('jszip');

const levels = ['3rd Year', '4th Year', 'Other'];

export interface paramsProps {
    companyId: string;
    vacancyId: string;
}

export interface userProps {
    user: any;
}

export default function VacancyProfile() {
    const auth = useAuth();
    const params = useParams<paramsProps>();
    const userType = auth.userType;
    const [downloadLoading, setDownloadLoading] = useState(false);
    const company = auth.company;
    const queryClient = useQueryClient();

    const { data: vacancy, isLoading } = useQuery({
        queryKey: [QueryKeys.COMPANY_PROFILE, 'vacancy', params.vacancyId, auth.userType],
        queryFn: () => companyApi.getVacancy(params.vacancyId, userType).then((e) => e.data),
    });

    const markNewDownloads = async (applications: ApplicationBase[]) => {
        const newApplications = applications.filter((e: any) => !e.lastDownloadAt);
        const ids = newApplications.map((e: any) => e._id);
        await Promise.all(ids.map((id) => vacancyApi.markDownload(params.vacancyId, id)));
    };

    const downloadAll = async (onlyNew = false) => {
        await Promise.all([
            downloadZip(onlyNew),
            onlyNew ? markNewDownloads(vacancy?.applications) : vacancyApi.markDownload(params.vacancyId),
        ]);
        queryClient.refetchQueries([QueryKeys.COMPANY_PROFILE, 'vacancy', params.vacancyId, auth.userType]);
    };

    const downloadZip = async (onlyNew = false) => {
        setDownloadLoading(true);
        let zip = new JSZip();
        let f4 = zip.folder('4th Year Fresh Graduates');
        let f3 = zip.folder('3rd Year Fresh Graduates');
        let f5 = zip.folder('Other');
        const applications = [...(vacancy?.applications || [])];
        for (let i = 0; i < applications.length; i++) {
            if (onlyNew && applications[i].lastDownloadAt) {
                continue;
            }
            const application = applications[i];
            const res = await fetch(application.cvId.url).then((response) => response.blob());
            const file = new Blob([res]);
            const fileName = `${application.cvId.studentId.studentId.name}.pdf`;
            if (applications[i].level === '3') {
                f3.file(fileName, file);
            } else if (applications[i].level === '4') {
                f4.file(fileName, file);
            } else {
                f5.file(fileName, file);
            }
        }
        return zip
            .generateAsync({ type: 'blob' })
            .then(function (content: any) {
                saveAs(content, `${vacancy.jobTitle} UCSC Career Fair.zip`);
                setDownloadLoading(false);
            })
            .catch((err: any) => {
                // setSnack({ open: true, text: 'Unable to download CVs at the moment', color: 'error' });
                setDownloadLoading(false);
            });
    };

    const downloadCV = async (applicant: any) => {
        const download = async () => {
            const res = await fetch(applicant.cvId.url).then((response) => response.blob());
            const file = new Blob([res]);
            saveAs(file, `${vacancy.jobTitle} - ${applicant.cvId.cvName}`);
        };

        await Promise.all([download(), vacancyApi.markDownload(params.vacancyId, applicant._id)]);
        queryClient.refetchQueries([QueryKeys.COMPANY_PROFILE, 'vacancy', params.vacancyId, auth.userType]);
    };

    return isLoading || !vacancy || !company ? (
        <div>
            <CircularProgress color="primary" />
        </div>
    ) : (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Link to={'/company/vacancies/'}>
                <Button variant="outline" colorScheme="blue" mb={6}>
                    <ArrowBackIcon />
                    &nbsp; Back to Vacancies
                </Button>
            </Link>
            <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <HStack justifyContent="space-between" px={6} mb={4}>
                    <div>
                        <Heading size={'md'}>Job Title : {vacancy.jobTitle}</Heading>
                    </div>
                    <div>
                        <ViewAdvertModel vacancy={vacancy} />
                        &nbsp;
                        <VacancyForm vacancy={vacancy} />
                    </div>
                </HStack>

                <hr></hr>
                <Heading size={'sm'} mb={0} mt={3} mx={6}>
                    Applicants{' '}
                </Heading>
                <HStack mx={6} mb={6} justifyContent={'space-between'}>
                    {company.cvVisible ? (
                        <>
                            <h2>Total Applications : {vacancy?.applications?.length} </h2>
                            <HStack>
                                <Button
                                    size="sm"
                                    variant={'solid'}
                                    disabled={vacancy?.applications?.filter((e: any) => !e.lastDownloadAt).length === 0}
                                    onClick={() => downloadAll(true)}
                                    colorScheme={'green'}
                                    isLoading={downloadLoading}
                                    title="Download new applications"
                                >
                                    <DownloadIcon />
                                    &nbsp; Download New (
                                    {vacancy?.applications?.filter((e: any) => !e.lastDownloadAt).length})
                                </Button>
                                <Button
                                    size="sm"
                                    variant={'outline'}
                                    disabled={!vacancy?.applications?.length}
                                    onClick={() => downloadAll(false)}
                                    colorScheme={'linkedin'}
                                    isLoading={downloadLoading}
                                    title="Download all applications"
                                >
                                    <DownloadIcon />
                                    &nbsp; Download All ({vacancy?.applications?.length})
                                </Button>
                            </HStack>
                        </>
                    ) : (
                        <>
                            <h2>Total Applications: 0 </h2>
                            <Button size="md" variant={'solid'} disabled={true} colorScheme={'teal'}>
                                <DownloadIcon /> &nbsp; Download All
                            </Button>
                        </>
                    )}
                </HStack>

                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th width={'0%'}>#</Th>
                                <Th>Name</Th>
                                <Th>Level</Th>
                                <Th>Applied on</Th>
                                <Th>Last Download On</Th>
                                <Th align="right">CV</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {company.cvVisible && vacancy?.applications?.length ? (
                                vacancy.applications
                                    .toSorted((a: any, b: any) =>
                                        new Date(b.createdAt || Date.now()).getTime() -
                                            new Date(a.createdAt || Date.now()).getTime() >
                                        0
                                            ? 1
                                            : -1
                                    )
                                    .map((applicant: any, index: number) => {
                                        return (
                                            <Tr key={applicant._id}>
                                                <Td>{index + 1}</Td>
                                                <Td>{applicant.cvId.studentId?.studentId?.name}</Td>
                                                <Td> {levels[applicant.level - 3]}</Td>
                                                <Td>
                                                    {new Date(applicant.createdAt)
                                                        .toLocaleString('en-GB', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: true,
                                                        })
                                                        .toUpperCase()}
                                                </Td>
                                                <Td>
                                                    {applicant.lastDownloadAt ? (
                                                        new Date(applicant.lastDownloadAt)
                                                            .toLocaleString('en-GB', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true,
                                                            })
                                                            .toUpperCase()
                                                    ) : (
                                                        <Tag colorScheme={'green'} variant="solid">
                                                            New
                                                        </Tag>
                                                    )}
                                                </Td>
                                                <Td align="right">
                                                    <Button onClick={() => downloadCV(applicant)}>
                                                        <DownloadIcon style={{ marginRight: '16px' }} />
                                                        Download
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        );
                                    })
                            ) : (
                                <Tr>
                                    <Td colSpan={4} textAlign="center">
                                        <Alert status="warning" borderRadius="5px" justifyContent="center">
                                            <AlertIcon />
                                            <AlertDescription>No Applications.</AlertDescription>
                                        </Alert>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
}
