import { DeleteIcon, DownloadIcon, EditIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { QueryKeys } from 'constant/queryKeys';
import { useAuth } from 'contexts/AuthContext';
import fileApi from 'core/api/file.api';
import vacancyApi from 'core/api/vacancy.api';
import { Vacancy } from 'core/models/vacancy.model';
import { useEffect, useState } from 'react';
import { MdUpload } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import Dropzone from 'views/admin/profile/components/Dropzone';

export function VacancyForm(props: { vacancy: Vacancy | null }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [editMode, setEditMode] = useState(!(props.vacancy === null || props.vacancy === ({} as Vacancy)));
    const [vacancy, setVacancy] = useState({} as Vacancy);
    const [file, setFile] = useState<File | null>(null);
    const [viewDropZone, setViewDropZone] = useState(true);
    const [error, setError] = useState('');
    const toast = useToast();

    const auth = useAuth();
    const queryClient = useQueryClient();

    const handleVacancyAction = (vacancy: Vacancy, editMode: boolean) => {
        // setLoading(true);
        if (editMode) {
            delete vacancy.applications;
            vacancyApi
                .editVacancy(vacancy._id as string, vacancy)
                .then((res) => {
                    queryClient.refetchQueries([QueryKeys.COMPANY_PROFILE]);
                    toast({ title: 'Vacancy updated', status: 'success' });
                    handleClose();
                    setIsLoading(false);
                })
                .catch((err) => {
                    // setLoading(false);
                    toast({ title: 'Unable to update vacancy at the moment', status: 'error' });
                    setIsLoading(false);
                });
        } else {
            vacancyApi
                .addVacancy(vacancy, auth.company._id || '')
                .then((res) => {
                    queryClient.refetchQueries([QueryKeys.COMPANY_PROFILE]);
                    toast({ title: 'Vacancy added successfully', status: 'success' });
                    handleClose();
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast({ title: 'Unable to add vacancy at the moment', status: 'error' });
                });
        }
    };

    useEffect(() => {
        if (props.vacancy === null || props.vacancy === ({} as Vacancy)) {
            setEditMode(false);
            setVacancy({ adURL: '', jobTitle: '', description: '' });
            setFile(null);
            setViewDropZone(true);
        } else {
            setEditMode(true);
            setViewDropZone(false);
            setFile(null);
            setVacancy(props.vacancy);
        }
        setIsLoading(false);
        setError('');
    }, [props.vacancy, isOpen]);

    const handleAction = () => {
        setIsLoading(true);

        if (vacancy.jobTitle.trim() === '' || vacancy.description === '') {
            setError('Job Title & Job Summary is required');
            setIsLoading(false);
            return;
        }

        const url = `https://placehold.co/800x500/f0fafa/black?text=${auth?.company.name} - ${vacancy.jobTitle}&font=roboto`;

        if (!vacancy.adURL.trim()) {
            vacancy.adURL = url;
        }

        if (file === null && editMode) {
            if (vacancy.adURL.includes('placehold.co')) {
                vacancy.adURL = url;
            }
            handleVacancyAction(vacancy, editMode);
        } else if (file !== null) {
            var payload = new FormData();
            payload.append('file', file);
            fileApi
                .uploadFile(payload)
                .then((res) => {
                    vacancy.adURL = res.data.url;
                    handleVacancyAction(vacancy, editMode);
                })
                .catch((err) => {
                    toast({ title: 'Unable to upload file at the moment', status: 'error' });
                    setFile(null);
                });
        } else if (file === null && !editMode) {
            vacancy.adURL = url;
            handleVacancyAction(vacancy, editMode);
        }
    };

    const handleChange = (e: any) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setVacancy({ ...vacancy, [name]: value });
    };

    const handleClose = () => {
        onClose();
    };

    const handleFileChange = async (file: File) => {
        if (file) {
            setFile(file);
        } else {
            setFile(null);
        }
    };

    return (
        <>
            {editMode ? (
                <Button
                    onClick={onOpen}
                    variant="outline"
                    size="sm"
                    colorScheme="gray"
                    isDisabled={auth?.company?.vacancyUpdatable === false}
                >
                    <EditIcon /> &nbsp; Edit
                </Button>
            ) : (
                <Button
                    variant={'solid'}
                    colorScheme="brand"
                    onClick={onOpen}
                    isDisabled={auth?.company?.vacancyUpdatable === false}
                >
                    Create Vacancy
                </Button>
            )}
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent py={3} px={2}>
                    <ModalHeader> {editMode ? 'Update' : 'New'} Vacancy </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isDisabled={isLoading}>
                            <FormLabel>Job Title</FormLabel>
                            <Input name="jobTitle" value={vacancy.jobTitle} onChange={handleChange} />
                        </FormControl>
                        <FormControl mt={3} isDisabled={isLoading}>
                            <FormLabel>Job Description</FormLabel>
                            <Textarea
                                name="description"
                                value={vacancy.description}
                                onChange={handleChange}
                                resize={'vertical'}
                                rows={8}
                            />
                        </FormControl>

                        <FormControl mt={3}>
                            <FormLabel>Job Advertisement</FormLabel>
                            {editMode ? (
                                <Box mb={3}>
                                    {vacancy.adURL ? (
                                        <>
                                            <Link color="teal.500" href={vacancy.adURL} target="_blank">
                                                <Button colorScheme="teal" size="sm">
                                                    <DownloadIcon /> &nbsp; Current Advert
                                                </Button>
                                            </Link>
                                            <Button
                                                variant="outline"
                                                size={'sm'}
                                                ml={3}
                                                colorScheme="red"
                                                onClick={() => {
                                                    setVacancy((v) => ({ ...v, adURL: '' }));
                                                    setViewDropZone(true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                            &nbsp;&nbsp; |
                                        </>
                                    ) : null}

                                    {viewDropZone ? null : (
                                        <Button
                                            variant="outline"
                                            size={'sm'}
                                            ml={3}
                                            onClick={() => setViewDropZone(true)}
                                        >
                                            <EditIcon /> &nbsp; Edit Advert
                                        </Button>
                                    )}
                                </Box>
                            ) : null}

                            {viewDropZone ? (
                                <Dropzone
                                    bg="gray.50"
                                    onFilesChange={handleFileChange}
                                    accept={['.png', '.jpg', '.jpeg', '.pdf']}
                                    content={
                                        <Box>
                                            <Icon as={MdUpload} w="80px" h="60px" color="secondaryGray.700" />
                                            <Flex justify="center" mx="auto" mb="12px">
                                                <Text fontSize="md" fontWeight="600" color="secondaryGray.700">
                                                    Upload {editMode ? 'New' : ''} Advertisement
                                                </Text>
                                            </Flex>
                                        </Box>
                                    }
                                />
                            ) : null}
                        </FormControl>

                        {error ? (
                            <Alert status="error" mt={3}>
                                <AlertIcon />
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        ) : null}
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="outline" colorScheme="red" mr={3} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={handleAction} px={6} isLoading={isLoading}>
                            {editMode ? 'Save' : 'Create'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
