import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { Company } from 'core/models/company.model';
import { useCompanyListQuery } from 'core/queries';
import { Link } from 'react-router-dom';

export function CompaniesList() {
    const { companies } = useCompanyListQuery();

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Box
                style={{
                    gridGap: '1rem',
                    display: 'grid',
                    gap: '1.5rem',
                    gridTemplateColumns: 'repeat(auto-fit,minmax(160px,1fr))',
                    margin: '0 auto',
                    maxWidth: '1200px',
                }}
            >
                {companies
                    .filter((e: Company) => e.isPublic)
                    .map((company: Company) => (
                        <Link to={'./companies/' + company._id} key={company._id}>
                            <Card
                                p={0}
                                height="100%"
                                overflow="hidden"
                                border="1px solid transparent"
                                transition={'all 0.3s ease'}
                                style={{ aspectRatio: '1', maxWidth: 320 }}
                                borderRadius="md"
                                color={'gray.500'}
                                _hover={{ borderColor: 'gray.700', color: 'gray.700' }}
                            >
                                <Stack height="100%" justifyContent={'center'}>
                                    <Flex
                                        style={{ aspectRatio: '1.75' }}
                                        p="1"
                                        m={1}
                                        mb={0}
                                        mx={6}
                                        alignItems="center"
                                        background={`url('${company.logoURL}')`}
                                        backgroundSize="contain"
                                        backgroundRepeat={'no-repeat'}
                                        backgroundPosition={'center'}
                                    ></Flex>
                                    <Flex textAlign="center" pt={1} px={4} pb={3} justifyContent="center">
                                        <Text size="sm">{company.name}</Text>
                                    </Flex>
                                </Stack>
                            </Card>
                        </Link>
                    ))}
            </Box>
        </Box>
    );
}
