import React from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    Textarea,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { OptionBase } from 'chakra-react-select';
import { UserRole } from 'core/models/user.model';
import { useForm } from 'react-hook-form';

//Custom Components
import { ControlledSelect } from 'components/controlledSelect/ControlledSelect';
import DefaultAuth from 'layouts/auth/Default';

import studentApi from 'core/api/student.api';
import { StudentCreateRequest } from 'core/models/student.model';

// Assets
import illustration from 'assets/img/auth/banner-2025.png';
import logo from 'assets/img/logo.png';

function Register() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setError,
        clearErrors,
        setValue,
        watch,
    } = useForm({ reValidateMode: 'onBlur', mode: 'onBlur' });
    const [show, setShow] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const history = useHistory();
    const toast = useToast();
    const [errorMsg, setErrorMsg] = React.useState('');

    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');

    interface SelectOption extends OptionBase {
        label: string;
        value: string | number;
    }

    const DegreeProgramOptions: SelectOption[] = [
        {
            label: 'BSc. Information Systems',
            value: 'IS3',
        },
        {
            label: 'BSc. (Hons) Information Systems',
            value: 'IS4',
        },
        {
            label: 'BSc. Computer Science',
            value: 'CS3',
        },
        {
            label: 'BSc. (Hons) Computer Science',
            value: 'CS4',
        },
        {
            label: 'BSc. (Hons) Software Engineering',
            value: 'SE',
        },
    ];

    const CategoryOptions: SelectOption[] = [
        {
            label: '3rd Year Fresher',
            value: 3,
        },
        {
            label: '4th Year Fresher',
            value: 4,
        },
        // {
        //     label: 'Other',
        //     value: 5,
        // },
    ];

    const handleClick = () => setShow(!show);

    const trapSpacesForRequiredFields = (value: string) => value.length === 0 || value.trim().length > 0 || 'Required';

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        setErrorMsg('');
        try {
            const student: StudentCreateRequest = {
                name: data.firstName.trim() + ' ' + data.lastName.trim(),
                regNo: data.registrationNo,
                indexNo: data.indexNo,
                level: data.category.value,
                email: data.email,
                password: data.password,
                role: UserRole.Student,
                degree: data.degreeProgram.label,
                linkedIn: data.linkedIn,
                personalSite: data.personalSite,
                description: data.description,
                profilePic: '',
            };
            await studentApi.createStudent(student);
            toast({
                title: 'Account Created!',
                description: 'Registration completed successfully',
                status: 'success',
                variant: 'solid',
                duration: 9000,
                isClosable: true,
            });
            history.push('/auth/sign-in');
        } catch (error: any) {
            if (error?.response?.data?.message) {
                setErrorMsg(error?.response?.data?.message);
            } else {
                toast({
                    title: 'Request Failed!',
                    description: 'The request failed to process, please try again later',
                    status: 'error',
                    variant: 'solid',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
        setIsLoading(false);
    };

    const enum FieldType {
        Reg = 'registration',
        Index = 'index',
    }

    const validateIndexNo = (value: string, field: FieldType) => {
        let error: string = '';
        const regYearInput = getValues('registrationNo');
        const regNo: string = regYearInput.includes('/') ? regYearInput.split('/')[0] : '';
        if (regNo.length <= 0) {
            error = 'Required';
        } else {
            let regYear: string = '';

            if (regNo.length >= 4) {
                const firstFourDigits = regNo.slice(0, 4);
                if (firstFourDigits >= '2015' && firstFourDigits <= '2019') {
                    regYear = firstFourDigits.slice(2);
                } else if (firstFourDigits >= '2020' && firstFourDigits <= '2022') {
                    regYear = firstFourDigits.slice(2);
                }
            }

            if (!regYear) {
                error = 'Invalid registration year';
            }

            const pattern = new RegExp(`^${regYear}0[0-9]{5}$`, 'g');
            const result = pattern.test(value);

            if (!result) {
                error = 'Index number invalid';
            }
        }

        if (field === FieldType.Reg) {
            if (error.length > 0) setError('indexNo', { type: 'custom', message: error });
            else clearErrors('indexNo');
            return true;
        } else {
            if (error.length > 0) return error;
            else return true;
        }
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '20px', md: '10vh' }}
                flexDirection="column"
                minWidth={{ base: '100%', md: '500px' }}
            >
                <Box mb={4} style={{ width: '100%' }} mt={-4} textAlign="center">
                    <Image src={logo} alt="Logo" style={{ height: '8rem', margin: '0 auto' }} />
                </Box>
                <Box me="auto">
                    <Heading color={textColor} fontSize="28px" mb="10px">
                        Register
                    </Heading>
                    <Text mb={4} color={textColorSecondary} fontWeight="400" fontSize="md">
                        Step into the spotlight, create an account!
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '500px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                    as="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Alert status="info" borderRadius="5px" justifyContent="center" mb={8}>
                        <AlertIcon />
                        <AlertDescription lineHeight={1.5} fontSize={'small'} ps={1}>
                            If you are facing any issues, please contact us for support at{' '}
                            <a
                                href={'mailto:careerfair@ucsc.cmb.ac.lk'}
                                style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                            >
                                careerfair@ucsc.cmb.ac.lk
                            </a>
                            .
                        </AlertDescription>
                    </Alert>

                    <Flex direction="row" gap="4" mb="24px">
                        <FormControl isInvalid={!!errors.firstName} id="firstName">
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                First name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                id="firstName"
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                placeholder="First Name"
                                {...register('firstName', {
                                    required: 'Required',
                                    validate: trapSpacesForRequiredFields,
                                })}
                                errorBorderColor="red.500"
                            />
                            <FormErrorMessage> {`${errors.firstName?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.lastName}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Last name <Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                placeholder="Last Name"
                                {...register('lastName', {
                                    required: 'Required',
                                    validate: trapSpacesForRequiredFields,
                                })}
                            />
                            <FormErrorMessage> {`${errors.lastName?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                    </Flex>

                    <Flex direction="row" gap="4" mb="24px">
                        <FormControl isInvalid={!!errors.registrationNo}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Registration Number<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                type="text"
                                placeholder="202x/XX/XXX"
                                {...register('registrationNo', {
                                    required: 'Required',
                                    validate: {
                                        trapSpacesForRequiredFields,
                                        // validateIndexNo: () => validateIndexNo(getValues('indexNo'), FieldType.Reg),
                                    },
                                    pattern: {
                                        value: /^(201[6-9]|202[0-1])\/[cCiI][sS]\/[0-2][0-9]{2}$/g,
                                        message: 'Invalid Registration Number',
                                    },
                                })}
                                // onChange={(event) => {
                                //     const inputString = event.target.value;
                                //     let categoryValue: SelectOption;
                                //     if (inputString.includes('2020'))
                                //         categoryValue = CategoryOptions.find((obj) => obj.value === 4);
                                //     else if (inputString.includes('2021'))
                                //         categoryValue = CategoryOptions.find((obj) => obj.value === 3);
                                //     // else categoryValue = CategoryOptions.find((obj) => obj.value === '5');
                                //     setValue('category', categoryValue);
                                //     clearErrors('category');
                                // }}
                            />
                            <FormErrorMessage> {`${errors.registrationNo?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.indexNo}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Index Number<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                placeholder="2000xxxx"
                                type="number"
                                {...register('indexNo', {
                                    required: 'Required',
                                    validate: {
                                        trapSpacesForRequiredFields,
                                        validateIndexNo: (value) => validateIndexNo(value, FieldType.Index),
                                    },
                                })}
                            />
                            <FormErrorMessage> {`${errors.indexNo?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                    </Flex>

                    <Flex direction="row" gap="4" mb="24px">
                        <ControlledSelect
                            control={control}
                            name="degreeProgram"
                            label="Degree Program"
                            focusBorderColor="secondaryGray.100"
                            selectedOptionStyle="check"
                            options={DegreeProgramOptions}
                            chakraStyles={{
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    bg: 'transparent',
                                    px: 2,
                                    cursor: 'inherit',
                                }),
                                placeholder: () => ({
                                    color: 'secondaryGray.600',
                                    fontWeight: '400',
                                    fontSize: 'sm',
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontWeight: '500',
                                    color: 'navy.700',
                                    fontSize: 'sm',
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: 'none',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontWeight: '500',
                                    color: 'navy.700',
                                    fontSize: 'sm',
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    border: '1px solid',
                                    borderColor: 'secondaryGray.100',
                                    borderRadius: '16px',
                                }),
                            }}
                            rules={{ required: 'Please select your degree program' }}
                        />

                        <ControlledSelect
                            control={control}
                            name="category"
                            label="Category"
                            focusBorderColor="secondaryGray.100"
                            options={CategoryOptions}
                            placeholder="Select..."
                            selectedOptionStyle="check"
                            chakraStyles={{
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    bg: 'transparent',
                                    px: 2,
                                    cursor: 'inherit',
                                }),
                                placeholder: () => ({
                                    color: 'secondaryGray.600',
                                    fontWeight: '400',
                                    fontSize: 'sm',
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontWeight: '500',
                                    color: 'navy.700',
                                    fontSize: 'sm',
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: 'none',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontWeight: '500',
                                    color: 'navy.700',
                                    fontSize: 'sm',
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    border: '1px solid',
                                    borderColor: 'secondaryGray.100',
                                    borderRadius: '16px',
                                }),
                            }}
                            rules={{
                                required: 'Please select a category.',
                            }}
                        />
                    </Flex>

                    <FormControl isInvalid={!!errors.email} mb="24px">
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            fontWeight="500"
                            type="email"
                            placeholder="mail@simmmple.com"
                            {...register('email', { required: 'Required', validate: trapSpacesForRequiredFields })}
                        />
                        <FormErrorMessage> {`${errors.email?.message}` || ''}</FormErrorMessage>
                    </FormControl>

                    <Flex direction="row" gap="4" mb="24px">
                        <FormControl isInvalid={!!errors.linkedIn}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                LinkedIn
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                placeholder="LinkedIn URL"
                                {...register('linkedIn', {
                                    validate: trapSpacesForRequiredFields,
                                    pattern: {
                                        value: /((http|https):\/\/)(www.)linkedin.com\/in\/[a-zA-Z0-9-]+/g,
                                        message: 'Invalid LinkedIn URL',
                                    },
                                })}
                            />
                            <FormErrorMessage> {`${errors.linkedIn?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.personalSite}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Personal Site
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                placeholder="Personal Site URL"
                                {...register('personalSite', {
                                    validate: trapSpacesForRequiredFields,
                                    pattern: {
                                        value: /((http|https):\/\/)(www.)?[a-zA-Z0-9-]+[.][a-zA-Z0-9]+/g,
                                        message: 'Invalid URL',
                                    },
                                })}
                            />
                            <FormErrorMessage> {`${errors.personalSite?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                    </Flex>

                    <FormControl isInvalid={!!errors.description} mb="24px">
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Bio <Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Textarea
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            fontWeight="500"
                            style={{ border: '1px solid', borderColor: '#E0E5F2', borderRadius: '16px' }}
                            placeholder="Add a little description about yourself"
                            {...register('description', {
                                required: 'Required',
                                validate: trapSpacesForRequiredFields,
                            })}
                        />
                        <FormErrorMessage> {`${errors.description?.message}` || ''}</FormErrorMessage>
                    </FormControl>
                    <Flex direction="row" gap="4" mb="px">
                        <FormControl isInvalid={!!errors.password} mb="24px">
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size="md">
                                <Input
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: '0px', md: '0px' }}
                                    fontWeight="500"
                                    placeholder=""
                                    type={show ? 'text' : 'password'}
                                    {...register('password', {
                                        required: 'Required',
                                        minLength: {
                                            value: 8,
                                            message: 'Password must have at least 8 characters',
                                        },
                                        validate: trapSpacesForRequiredFields,
                                    })}
                                />
                                <InputRightElement display="flex" alignItems="center">
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: 'pointer' }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage> {`${errors.password?.message}` || ''}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.confirmPassword} mb="24px">
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Confirm Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size="md">
                                <Input
                                    fontSize="sm"
                                    placeholder=""
                                    type={show ? 'text' : 'password'}
                                    variant="auth"
                                    {...register('confirmPassword', {
                                        required: 'Required',
                                        minLength: {
                                            value: 8,
                                            message: 'Password must have atleast 8 characters',
                                        },
                                        validate: {
                                            trapSpacesForRequiredFields,
                                            match: (val: string) => {
                                                return watch('password') === val || 'Passwords should match!';
                                            },
                                        },
                                    })}
                                />
                                <InputRightElement display="flex" alignItems="center">
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: 'pointer' }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage> {`${errors.confirmPassword?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                    </Flex>
                    <Box>
                        {errorMsg ? (
                            <Alert variant={'left-accent'} status="error" mb={'5'}>
                                <AlertIcon /> {errorMsg}
                            </Alert>
                        ) : null}
                    </Box>
                    <Flex justifyContent="space-between" align="center" direction={'column'} pb="3rem">
                        <Button
                            variant="brand"
                            fontWeight="500"
                            h="50"
                            w="100%"
                            type="submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                        >
                            Create Account
                        </Button>
                        <Text color={textColorDetails} fontWeight="400" fontSize="14px" mt={'1rem'}>
                            Have an account?
                            <NavLink to="/auth/sign-in">
                                <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                                    Sign In
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default Register;
