import { Icon } from '@chakra-ui/react';
import {
    MdAltRoute,
    MdCorporateFare,
    MdHome,
    MdHomeRepairService,
    MdLock,
    MdOutlineGridView,
    MdOutlineStar,
    MdOutlineWorkHistory,
    MdPermContactCalendar,
    MdStore,
    MdWorkOutline,
} from 'react-icons/md';
import { PiStudentBold } from 'react-icons/pi';
import { TbChartTreemap } from 'react-icons/tb';
// Company Imports
import CompanyCandidates from 'views/company/candidates';
import CompanyProfile from 'views/company/profile';
import StallBooking from 'views/company/stall-booking';
import CompanyVacancies from 'views/company/vacancy';

// Admin Imports
import AdminCandidates from 'views/admin/candidates';
import AdminCompanyStatus from 'views/admin/company-status';
import MainDashboard from 'views/admin/default';
import StallsList from 'views/admin/stalls';
import AdminVacancies from 'views/admin/vacancies';

//Student Imports
import StudentVacancies from 'views/student/applied-vacancies';
import StudentCompanies from 'views/student/companies';

// Auth Imports
import Register from 'views/auth/register';
import ResetPassword from 'views/auth/reset-password';
import SignInCentered from 'views/auth/signIn';
import Profile from 'views/student/profile';
import Sponsorships from './views/company/sponsorships';
import WalkinsRegister from './views/auth/walkins-register';

const routes = [
    {
        name: 'Sign In',
        layout: '/auth',
        path: '/sign-in',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
        component: SignInCentered,
        isLocked: false,
    },
    {
        name: 'Reset Password',
        layout: '/auth',
        path: '/reset-password',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
        component: ResetPassword,
        isLocked: false,
    },
    {
        name: 'Register',
        layout: '/auth',
        path: '/sign-up',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
        component: Register,
        isLocked: false,
    },
    {
        name: 'Walk-ins Register',
        layout: '/auth',
        path: '/walkins-register',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
        component: WalkinsRegister,
        isLocked: false,
    },
    // Admin Routes
    {
        name: 'Companies',
        layout: '/admin',
        path: '/all-companies',
        icon: <Icon as={MdCorporateFare} width="20px" height="20px" color="inherit" />,
        component: MainDashboard,
        isLocked: false,
    },
    {
        name: 'Company Status',
        layout: '/admin',
        path: '/company-flags',
        icon: <Icon as={MdAltRoute} width="20px" height="20px" color="inherit" />,
        component: AdminCompanyStatus,
        isLocked: false,
    },
    {
        name: 'Stalls Summary',
        layout: '/admin',
        path: '/stall-summary',
        icon: <Icon as={MdStore} width="20px" height="20px" color="inherit" />,
        component: StallsList,
        isLocked: false,
    },
    {
        name: 'Stall Reservation Map',
        layout: '/admin',
        path: '/stalls',
        icon: <Icon as={TbChartTreemap} width="20px" height="20px" color="inherit" />,
        component: StallBooking,
        isLocked: false,
    },
    {
        name: 'Candidates',
        layout: '/admin',
        path: '/candidates',
        icon: <Icon as={PiStudentBold} width="20px" height="20px" color="inherit" />,
        component: AdminCandidates,
        isLocked: false,
    },
    {
        name: 'Vacancies',
        layout: '/admin',
        path: '/vacancies',
        icon: <Icon as={MdWorkOutline} width="20px" height="20px" color="inherit" />,
        component: AdminVacancies,
        isLocked: false,
    },
    {
        name: 'All Companies',
        layout: '/admin',
        path: '/companies',
        icon: <Icon as={MdOutlineGridView} width="20px" height="20px" color="inherit" />,
        component: StudentCompanies,
        isLocked: false,
    },

    // Company Routes
    {
        name: 'Your Company Profile',
        layout: '/company',
        path: '/profile',
        icon: <Icon as={MdCorporateFare} width="20px" height="20px" color="inherit" />,
        component: CompanyProfile,
        isLocked: false,
    },
    {
        name: 'Sponsorships',
        layout: '/company',
        path: '/sponsorships',
        icon: <Icon as={MdOutlineStar} width="20px" height="20px" color="inherit" />,
        component: Sponsorships,
        isLocked: false,
    },
    {
        name: 'Stall Reservation',
        layout: '/company',
        path: '/stalls',
        icon: <Icon as={MdStore} width="20px" height="20px" color="inherit" />,
        component: StallBooking,
        isLocked: false,
    },
    {
        name: 'Vacancies',
        layout: '/company',
        path: '/vacancies',
        icon: <Icon as={MdHomeRepairService} width="20px" height="20px" color="inherit" />,
        component: CompanyVacancies,
        isLocked: false,
    },
    {
        name: 'Talent Pool',
        layout: '/company',
        path: '/talent-pool',
        icon: <Icon as={MdPermContactCalendar} width="20px" height="20px" color="inherit" />,
        component: CompanyCandidates,
        isLocked: false,
    },
    {
        name: 'Student View',
        layout: '/company',
        path: '/companies',
        icon: <Icon as={MdOutlineGridView} width="20px" height="20px" color="inherit" />,
        component: StudentCompanies,
        isLocked: false,
    },

    // Student Routes
    {
        name: 'Student Profile',
        layout: '/student',
        path: '/profile',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
        component: Profile,
        isLocked: false,
    },
    {
        name: 'Applied Vacancies',
        layout: '/student',
        path: '/vacancies',
        icon: <Icon as={MdOutlineWorkHistory} width="20px" height="20px" color="inherit" />,
        component: StudentVacancies,
        isLocked: false,
    },
    {
        name: 'All Vacancies',
        layout: '/student',
        path: '/all-vacancies',
        icon: <Icon as={MdWorkOutline} width="20px" height="20px" color="inherit" />,
        component: AdminVacancies,
        isLocked: false,
    },
    {
        name: 'Companies',
        layout: '/student',
        path: '/companies',
        icon: <Icon as={MdCorporateFare} width="20px" height="20px" color="inherit" />,
        component: StudentCompanies,
        isLocked: false,
    },
    {
        name: 'Talent Pool',
        layout: '/student',
        path: '/talent-pool',
        icon: <Icon as={MdPermContactCalendar} width="20px" height="20px" color="inherit" />,
        component: CompanyCandidates,
        isLocked: false,
    },
];

export default routes;
