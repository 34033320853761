import profileApi from 'core/api/profile.api';
import { Company } from 'core/models/company.model';
import jwt from 'jwt-decode';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { loginWithEmailAndPassword, logout } from '../firebase';

export interface AuthContextType {
    isLoggedIn: boolean;
    user: undefined | any;
    userType: undefined | 'admin' | 'company' | 'student';
    token: undefined | string;
    company?: undefined | Company;
    update: () => void;
    login: (
        email: string,
        password: string
    ) => Promise<{
        success: boolean;
        errorMessage?: unknown;
        user?: undefined | any;
        userType?: undefined | 'admin' | 'company' | 'student';
        token?: undefined | string;
    }>;
    logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>(null);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }: any) => {
    const [update, setUpdate] = useState(false);

    const signOut = async () => {
        setAuthContextValue((authContextValue) => ({
            ...authContextValue,
            userType: null,
            user: null,
            token: null,
            isLoggedIn: false,
        }));
        logout();
    };

    const signIn = async (email: string, password: string): Promise<any> => {
        const { user, userType, token, success, errorMessage } = await loginWithEmailAndPassword(email, password);
        if (!success) return { success, errorMessage };

        setAuthContextValue((authContextValue) => ({
            ...authContextValue,
            userType,
            user,
            token,
            isLoggedIn: true,
        }));

        return { user, userType, token, success: true };
    };

    const [authContextValue, setAuthContextValue] = useState<AuthContextType>({
        isLoggedIn: false,
        user: undefined,
        userType: undefined,
        token: undefined,
        login: signIn,
        logout: signOut,
        update: () => setUpdate((e) => !e),
    });

    useMemo(() => {
        const token = localStorage.getItem('API-AUTH-TOKEN');
        if (token) {
            const user_decode: any = jwt(token);
            // console.log('User will be signed out on: ', new Date(user_decode.exp * 1000));
            // check if token expired
            if (Date.now() < user_decode.exp * 1000 - 60000) {
                setAuthContextValue((authContextValue) => ({
                    ...authContextValue,
                    userType: user_decode.role,
                    user: user_decode,
                    token: token,
                    isLoggedIn: true,
                }));

                setTimeout(
                    () => {
                        signOut();
                    },
                    user_decode.exp * 1000 - Date.now()
                );
            } else {
                signOut();
            }
        }
    }, []);

    useEffect(() => {
        if (authContextValue.userType === 'company') {
            profileApi
                .getCompanyProfile()
                .then((res) => {
                    setAuthContextValue((value) => ({ ...value, company: res.data }));
                })
                .catch((e) => {
                    console.error('Could not fetch company profile: ', e);
                });
        }
    }, [authContextValue.userType, update]);

    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
