import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    HStack,
    Image,
    Input,
    Select,
    Spacer,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AppAlert } from 'contexts/AppAlert';
import { useAuth } from 'contexts/AuthContext';
import companyApi from 'core/api/company.api';
import { Company, CompanyFilters } from 'core/models/company.model';
import { useEffect, useState } from 'react';
import { TbSwitch3 } from 'react-icons/tb';
import { SponsorshipModel } from '../../../core/models/sponsorship.model';
import sponsorshipApi from '../../../core/api/sponsorship.api';

export interface AdminStallManagementProps {}
export interface AdminCompanyManagementProps {
    openDrawer: (drawerProp: { mode: 'CREATE' | 'UPDATE'; prop?: any }) => void;
}

const initialFilters: CompanyFilters = {
    name: '',
    email: '',
};

export default function CompanyStatus() {
    const auth = useAuth();
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [stats, setStats] = useState([
        {
            label: 'Total Companies',
            value: 0,
        },
        {
            label: 'Vacancies Updatable',
            value: 0,
        },
        {
            label: 'CVs Submittable',
            value: 0,
        },
        {
            label: 'CVs Visible',
            value: 0,
        },
        {
            label: 'Payment completed',
            value: 0,
        },
    ]);

    const [page, setPage] = useState(0);
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const toast = useToast();

    const [companies, setCompanies] = useState<Company[]>([]);
    const [sponsorships, setSponsorships] = useState<SponsorshipModel[]>([]);

    const [filterFormValues, setFilterFormValues] = useState({ ...initialFilters });
    const [activeFilterValues, setActiveFilterValues] = useState({ ...initialFilters });

    const handleFilterFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterFormValues({
            ...filterFormValues,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        companyApi.getSummaryForList().then((res) => {
            setStats([
                {
                    label: 'Total Companies',
                    value: res.totalCount,
                },
                {
                    label: 'Vacancies Updatable',
                    value: res.vacancyUpdatable,
                },
                {
                    label: 'CVs Submittable',
                    value: res.cvSubmitable,
                },
                {
                    label: 'CVs Visible',
                    value: res.cvVisible,
                },
                {
                    label: 'Payment completed',
                    value: res.isPaid,
                },
            ]);
        });

        companyApi.getCompanies(activeFilterValues, 0, 10000).then((res) => {
            setCompanies(res.data);
            setPage(res.page);
        });

        sponsorshipApi.getSponsorshipList().then((res) => {
            setSponsorships(res.data);
        });
    }, [auth, activeFilterValues, page, shouldRefresh]);

    const updateCompanyStatus = (id: string, name: string, availability: boolean, type: string) => {
        let content: string = '';

        if (type === 'cvVisible') content = 'CV Visibility';
        else if (type === 'vacancyUpdatable') content = 'Vacancy Updatable Status';
        else if (type === 'cvSubmitable') content = 'CV Submitable Status';
        else if (type === 'isPublic') content = 'Public Status';
        else if (type === 'isPaid') content = 'Payment Status';

        AppAlert.fire({
            title: `Are you sure you want to change the ${content} of ${name} to ${availability ? 'False' : 'True'}`,
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f12b1e',
            cancelButtonColor: '#656565',
            confirmButtonText: 'Yes, change it!',
        }).then((result) => {
            if (result.isConfirmed) {
                let update;
                if (type === 'cvVisible') {
                    update = companyApi.updateCvVisibility(id, !availability);
                } else if (type === 'vacancyUpdatable') {
                    update = companyApi.updateVacancyStatus(id, !availability);
                } else if (type === 'cvSubmitable') {
                    update = companyApi.updateCvSubmitStatus(id, !availability);
                } else if (type === 'isPublic') {
                    update = companyApi.updatePublicStatus(id, !availability);
                } else if (type === 'isPaid') {
                    update = companyApi.updatePaymentStatus(id, !availability);
                }

                update.then((res) => {
                    setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                    toast({
                        title: 'Details Updated!',
                        description: 'The company details were updated successfully',
                        status: 'success',
                        variant: 'solid',
                        duration: 4000,
                        isClosable: true,
                    });
                });
            }
        });
    };

    const updateSponsorShip = (companyId: string, sponsorshipId: string) => {
        const company = companies.filter((company) => company._id === companyId)[0].name;
        let sponsorShip = sponsorships.filter((sponsorship) => sponsorship._id === sponsorshipId)[0];
        sponsorShip = sponsorshipId !== 'null' ? sponsorShip : null;

        AppAlert.fire({
            title: `Are you sure you want to change the sponsorship status of ${company} to ${
                sponsorShip ? sponsorShip.package : 'None'
            }`,
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f12b1e',
            cancelButtonColor: '#656565',
            confirmButtonText: 'Yes, change it!',
        }).then((result) => {
            if (result.isConfirmed) {
                companyApi.updateSponsorship(companyId, sponsorShip).then((r) => {
                    setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                    console.log(r);
                });
            }
        });
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid gap={6} mb={6} gridTemplateColumns="1fr 1fr 1fr 1fr">
                {stats.map((stat) => (
                    <Card key={stat.label} py={3}>
                        <Stat>
                            <StatLabel>{stat.label}</StatLabel>
                            <StatNumber>{stat.value}</StatNumber>
                        </Stat>
                    </Card>
                ))}
            </Grid>

            <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <HStack mx={6} mb={6} alignItems="end">
                    <FormControl size="small">
                        <FormLabel id="stall-status-label">Company Name </FormLabel>
                        <Input
                            id="name"
                            name="name"
                            placeholder="Search Companies"
                            size="sm"
                            onChange={handleFilterFormChange}
                        />
                    </FormControl>
                    <Spacer flex={' 1 1 0'} />
                    <FormControl size="small">
                        <FormLabel id="stall-status-label">Email</FormLabel>
                        <Input
                            id="email"
                            name="email"
                            placeholder="Email"
                            size="sm"
                            onChange={handleFilterFormChange}
                        />
                    </FormControl>
                    <Button
                        variant="outline"
                        size="sm"
                        colorScheme={'teal'}
                        px={8}
                        onClick={() => {
                            setActiveFilterValues({ ...filterFormValues });
                            setPage(0);
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        px={8}
                        onClick={() => {
                            setShouldRefresh((prevShouldRefresh) => !prevShouldRefresh);
                            setActiveFilterValues({ ...initialFilters });
                            setFilterFormValues({ ...initialFilters });
                            setPage(0);
                        }}
                    >
                        Reset
                    </Button>
                </HStack>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th borderColor={borderColor} align="left">
                                    #
                                </Th>
                                <Th borderColor={borderColor} align="left"></Th>
                                <Th borderColor={borderColor} align="left">
                                    Name
                                </Th>

                                <Th borderColor={borderColor} align="left">
                                    Public
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Vacancy Update
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    CV Submission
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    CV Visible
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Paid (Stall+Sponsor)
                                </Th>
                                <Th borderColor={borderColor} align="left">
                                    Sponsorship Status
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {companies.map((company, index) => (
                                <Tr key={company._id} style={{ backgroundColor: company.sponsorship && '#f2e8c5' }}>
                                    <Td>{index + 1}</Td>
                                    <Td>
                                        <Image src={company.logoURL} maxHeight="2rem" maxWidth={'3rem'} />
                                    </Td>
                                    <Td>{company.name}</Td>

                                    <Td align="left">
                                        <Button
                                            size="sm"
                                            colorScheme={company['isPublic'] ? 'teal' : ''}
                                            variant={company['isPublic'] ? 'solid' : 'outline'}
                                            onClick={() => {
                                                updateCompanyStatus(
                                                    company['_id'] || 'undefined',
                                                    company['name'] || '',
                                                    company['isPublic'] || false,
                                                    'isPublic'
                                                );
                                            }}
                                        >
                                            <TbSwitch3 /> &nbsp; {company.isPublic ? 'YES' : 'NO'}
                                        </Button>
                                    </Td>
                                    <Td>
                                        <Button
                                            size="sm"
                                            colorScheme={company['vacancyUpdatable'] ? 'teal' : ''}
                                            variant={company['vacancyUpdatable'] ? 'solid' : 'outline'}
                                            onClick={() => {
                                                updateCompanyStatus(
                                                    company['_id'] || 'undefined',
                                                    company['name'] || '',
                                                    company['vacancyUpdatable'] || false,
                                                    'vacancyUpdatable'
                                                );
                                            }}
                                        >
                                            <TbSwitch3 />
                                            &nbsp; {company.vacancyUpdatable ? 'ALLOWED' : 'RESTRICTED'}
                                        </Button>
                                    </Td>

                                    <Td>
                                        <Button
                                            size="sm"
                                            colorScheme={company['cvSubmitable'] ? 'teal' : ''}
                                            variant={company['cvSubmitable'] ? 'solid' : 'outline'}
                                            onClick={() => {
                                                updateCompanyStatus(
                                                    company['_id'] || 'undefined',
                                                    company['name'] || '',
                                                    company['cvSubmitable'] || false,
                                                    'cvSubmitable'
                                                );
                                            }}
                                        >
                                            <TbSwitch3 />
                                            &nbsp; {company.cvSubmitable ? 'OPN' : 'CLOSED'}
                                        </Button>
                                    </Td>

                                    <Td>
                                        <Button
                                            size="sm"
                                            colorScheme={company['cvVisible'] ? 'teal' : ''}
                                            variant={company['cvVisible'] ? 'solid' : 'outline'}
                                            onClick={() => {
                                                updateCompanyStatus(
                                                    company['_id'] || 'undefined',
                                                    company['name'] || '',
                                                    company['cvVisible'] || false,
                                                    'cvVisible'
                                                );
                                            }}
                                        >
                                            <TbSwitch3 /> &nbsp; {company.cvVisible ? 'YES' : 'NO'}
                                        </Button>
                                    </Td>

                                    <Td>
                                        <Button
                                            size="sm"
                                            colorScheme={company['isPaid'] ? 'teal' : ''}
                                            variant={company['isPaid'] ? 'solid' : 'outline'}
                                            onClick={() => {
                                                updateCompanyStatus(
                                                    company['_id'] || 'undefined',
                                                    company['name'] || '',
                                                    company['isPaid'] || false,
                                                    'isPaid'
                                                );
                                            }}
                                        >
                                            <TbSwitch3 /> &nbsp; {company.isPaid ? 'YES' : 'NO'}
                                        </Button>
                                    </Td>

                                    <Td>
                                        <Select
                                            size="sm"
                                            value={company.sponsorship ? company.sponsorship : 'null'}
                                            onChange={(e) => {
                                                updateSponsorShip(company._id, e.target.value);
                                            }}
                                            colorScheme="teal"
                                            variant="outline"
                                        >
                                            <option value="null">Not a sponsor</option>
                                            {sponsorships.map((sponsorShip) => (
                                                <option
                                                    key={sponsorShip._id}
                                                    value={sponsorShip._id}
                                                    disabled={
                                                        sponsorShip.qtyAvailable === 0 || company.sponsorship !== null
                                                    }
                                                    style={{
                                                        color:
                                                            sponsorShip.qtyAvailable === 0 || company.sponsorship
                                                                ? 'gray'
                                                                : 'black',
                                                        cursor:
                                                            sponsorShip.qtyAvailable === 0 || company.sponsorship
                                                                ? 'not-allowed'
                                                                : 'pointer',
                                                    }}
                                                >
                                                    {sponsorShip.package}
                                                </option>
                                            ))}
                                        </Select>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
}
