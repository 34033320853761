import {
    Badge,
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    HStack,
    Image,
    Input,
    Select,
    Skeleton,
    SkeletonCircle,
    Spacer,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ViewAdvertModel } from 'components/view-advert/ViewAdvertModel';
import { useAuth } from 'contexts/AuthContext';
import vacancyApi from 'core/api/vacancy.api';
import { Vacancy, VacancyFilters } from 'core/models/vacancy.model';
import { useCompanyListQuery, useVacancyStatsQuery } from 'core/queries';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { ApplyModel } from 'views/student/companies/components/ApplyModel';

export interface AdminVacancyManagementProps {}

const initialFilters: VacancyFilters = {
    jobTitle: '',
    companyId: '',
};

export default function Vacancies() {
    const auth = useAuth();

    const { vacancyStats } = useVacancyStatsQuery();
    const [page, setPage] = useState(0);

    const [filterFormValues, setFilterFormValues] = useState({ ...initialFilters });
    const [activeFilterValues, setActiveFilterValues] = useState({ ...initialFilters });

    const handleFilterFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterFormValues({
            ...filterFormValues,
            [event.target.name]: event.target.value,
        });
    };

    const { companies } = useCompanyListQuery();

    const {
        data: vacancyData = { data: [] as Vacancy[], count: 10 },
        isLoading,
        isRefetching,
    } = useQuery(
        ['vacancies-list', page, activeFilterValues.companyId, activeFilterValues.jobTitle],
        () => {
            return vacancyApi.getAllVacancies(activeFilterValues, page, 10);
        },
        { refetchOnWindowFocus: false, refetchOnMount: false }
    );

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid gap={6} mb={6} gridTemplateColumns="1fr 1fr 1fr 1fr">
                {vacancyStats.map((stat) => (
                    <Card key={stat.label} py={3}>
                        <Stat>
                            <StatLabel>{stat.label}</StatLabel>
                            <StatNumber>{stat.value}</StatNumber>
                        </Stat>
                    </Card>
                ))}
            </Grid>

            <Card flexDirection="column" w="100%" px="0px" pb={0}>
                <HStack mx={6} mb={6} alignItems="end">
                    <FormControl size="small">
                        <FormLabel id="stall-status-label">Vacancy Title</FormLabel>
                        <Input
                            id="jobTitle"
                            name="jobTitle"
                            placeholder="Vacancy Title"
                            size="sm"
                            onChange={handleFilterFormChange}
                        />
                    </FormControl>
                    <Spacer flex={' 1 1 0'} />
                    <FormControl size="small">
                        <FormLabel id="stall-status-label">Company</FormLabel>
                        <Select
                            id="companyId"
                            name="companyId"
                            size="sm"
                            value={filterFormValues.companyId}
                            onChange={(event) => {
                                setFilterFormValues((prevFilterFormValues) => ({
                                    ...prevFilterFormValues,
                                    [event.target.name || 'name_missing']: event.target.value,
                                }));
                            }}
                        >
                            <option value={''}>Any</option>
                            {companies.map((e: any) => (
                                <option key={e._id} value={e._id}>
                                    {e.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="outline"
                        size="sm"
                        colorScheme={'teal'}
                        px={8}
                        onClick={() => {
                            setActiveFilterValues({ ...filterFormValues });
                            setPage(0);
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        px={8}
                        onClick={() => {
                            setActiveFilterValues({ ...initialFilters });
                            setFilterFormValues({ ...initialFilters });
                            setPage(0);
                        }}
                    >
                        Reset
                    </Button>
                </HStack>
                <TableContainer px={2}>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th align="left">#</Th>
                                <Th align="right"></Th>
                                <Th align="left" width={'100%'}>
                                    Vacancy Title
                                </Th>
                                <Th align="center"> Applicants</Th>
                                <Th align="center">Action</Th>
                                {auth.userType === 'admin' && <Th align="center">Opn/Cls By company</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isLoading || isRefetching
                                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((vacancy: any, index) => (
                                      <Tr key={vacancy}>
                                          <Td align="left"> </Td>
                                          <Td align="right">
                                              <SkeletonCircle height={'2rem'} width="3rem" />
                                          </Td>
                                          <Td align="left">
                                              <Skeleton height={'1em'} width="20em" />
                                          </Td>
                                          <Td align="center">
                                              <Skeleton height={'1em'} width="2em" />
                                          </Td>
                                          <Td align="center">
                                              <HStack>
                                                  <Skeleton height={'1em'} width="5em" />
                                                  &nbsp;
                                                  <Skeleton height={'1em'} width="5em" />
                                              </HStack>
                                          </Td>
                                      </Tr>
                                  ))
                                : vacancyData.data.map((vacancy: any, index) => (
                                      <Tr key={vacancy._id}>
                                          <Td align="left">{index + 10 * page + 1}</Td>
                                          <Td align="right" px={1} textAlign="center">
                                              <Image
                                                  src={vacancy.companyId.logoURL}
                                                  maxHeight="2rem"
                                                  maxWidth={'3rem'}
                                              />
                                          </Td>
                                          <Td align="left" pr={2} style={{ whiteSpace: 'break-spaces' }}>
                                              {vacancy.jobTitle}
                                          </Td>
                                          <Td align="center">{vacancy.applications.length} </Td>
                                          <Td align="center">
                                              <HStack>
                                                  <ViewAdvertModel vacancy={vacancy} /> &nbsp;
                                                  {auth.userType === 'student' && vacancy.isOpen ? (
                                                      <ApplyModel vacancy={vacancy} company={vacancy.companyId} />
                                                  ) : (
                                                      ''
                                                  )}
                                                  {auth.userType === 'student' && !vacancy.isOpen ? (
                                                      <Button disabled={true} colorScheme="red" size="sm">
                                                          Vacancy closed
                                                      </Button>
                                                  ) : (
                                                      ''
                                                  )}
                                              </HStack>
                                          </Td>
                                          {auth.userType === 'admin' ? (
                                              vacancy.isOpen ? (
                                                  <Td>
                                                      <Badge colorScheme="green" variant="solid">
                                                          Opened
                                                      </Badge>
                                                  </Td>
                                              ) : (
                                                  <Td>
                                                      <Badge colorScheme="red" variant="subtle">
                                                          Closed
                                                      </Badge>
                                                  </Td>
                                              )
                                          ) : (
                                              ''
                                          )}
                                      </Tr>
                                  ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                <ReactPaginate
                    containerClassName="table-pagination"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={(e) => setPage(e.selected)}
                    pageRangeDisplayed={5}
                    pageCount={vacancyData.count / 10}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </Card>
        </Box>
    );
}
