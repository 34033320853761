import React from 'react';
// Chakra imports
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Image,
    Input,
    Text,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { OptionBase } from 'chakra-react-select';
import { UserRole } from 'core/models/user.model';
import { useForm } from 'react-hook-form';

//Custom Components
import { ControlledSelect } from 'components/controlledSelect/ControlledSelect';
import DefaultAuth from 'layouts/auth/Default';

import studentApi from 'core/api/student.api';
import { StudentRegisterWalkInRequest } from 'core/models/student.model';

// Assets
import illustration from 'assets/img/auth/banner-2025.png';
import logo from 'assets/img/logo.png';

function WalkinsRegister() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setError,
        clearErrors,
        setValue,
    } = useForm({ reValidateMode: 'onBlur', mode: 'onBlur' });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const toast = useToast();
    const [errorMsg, setErrorMsg] = React.useState('');

    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('brand.500', 'brand.400');

    interface SelectOption extends OptionBase {
        label: string;
        value: string | number;
    }

    const CategoryOptions: SelectOption[] = [
        {
            label: '3rd Year Fresher',
            value: 3,
        },
        {
            label: '4th Year Fresher',
            value: 4,
        },
        // {
        //     label: 'Other',
        //     value: 5,
        // },
    ];

    const trapSpacesForRequiredFields = (value: string) => value.length === 0 || value.trim().length > 0 || 'Required';

    const onSubmit = async (data: any) => {
        // console.log(data);
        setIsLoading(true);
        setErrorMsg('');
        try {
            const student: StudentRegisterWalkInRequest = {
                name: data.name.trim(),
                regNo: data.registrationNo,
                indexNo: data.indexNo,
                level: data.category.value,
                email: data.email,
                role: UserRole.Student,
                telephone: data.phone,
            };

            const response = await studentApi.registerWalkIn(student);

            toast({
                title: 'Registration successful!',
                description: 'Good luck with your walk-ins!',
                status: 'success',
                variant: 'solid',
                duration: 9000,
                isClosable: true,
            });
            // clear form
            setValue('name', '');
            setValue('registrationNo', '');
            setValue('indexNo', '');
            setValue('email', '');
            setValue('phone', '');
            setValue('category', '');
        } catch (error: any) {
            if (error?.response?.data?.message) {
                setErrorMsg(error?.response?.data?.message);
            } else {
                toast({
                    title: 'Request Failed!',
                    description: 'The request failed to process, please try again later',
                    status: 'error',
                    variant: 'solid',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
        setIsLoading(false);
    };

    const enum FieldType {
        Reg = 'registration',
        Index = 'index',
    }

    const validateIndexNo = (value: string, field: FieldType) => {
        let error: string = '';
        const regYearInput = getValues('registrationNo');
        const regNo: string = regYearInput.includes('/') ? regYearInput.split('/')[0] : '';
        if (regNo.length <= 0) {
            error = 'Required';
        } else {
            let regYear: string = '';

            if (regNo.length >= 4) {
                const firstFourDigits = regNo.slice(0, 4);
                if (firstFourDigits >= '2015' && firstFourDigits <= '2019') {
                    regYear = firstFourDigits.slice(2);
                } else if (firstFourDigits >= '2020' && firstFourDigits <= '2022') {
                    regYear = firstFourDigits.slice(2);
                }
            }

            if (!regYear) {
                error = 'Invalid registration year';
            }

            const pattern = new RegExp(`^${regYear}0[0-9]{5}$`, 'g');
            const result = pattern.test(value);

            if (!result) {
                error = 'Index number invalid';
            }
        }

        if (field === FieldType.Reg) {
            if (error.length > 0) setError('indexNo', { type: 'custom', message: error });
            else clearErrors('indexNo');
            return true;
        } else {
            if (error.length > 0) return error;
            else return true;
        }
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '20px', md: '10vh' }}
                flexDirection="column"
                minWidth={{ base: '100%', md: '500px' }}
            >
                <Box mb={4} style={{ width: '100%' }} mt={-4} textAlign="center">
                    <Image src={logo} alt="Logo" style={{ height: '8rem', margin: '0 auto' }} />
                </Box>
                <Box me="auto">
                    <Heading color={textColor} fontSize="28px" mb="10px">
                        Walk-ins Register
                    </Heading>
                    <Text mb={4} color={textColorSecondary} fontWeight="400" fontSize="md">
                        Step into the spotlight, register for walk-ins!
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '500px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                    as="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Alert status="info" borderRadius="5px" justifyContent="center" mb={8}>
                        <AlertIcon />
                        <AlertDescription lineHeight={1.5} fontSize={'small'} ps={1}>
                            If you are facing any issues, please contact us for support at{' '}
                            <a
                                href={'mailto:careerfair@ucsc.cmb.ac.lk'}
                                style={{ fontStyle: 'italic', fontWeight: 'bold' }}
                            >
                                careerfair@ucsc.cmb.ac.lk
                            </a>
                            .
                        </AlertDescription>
                    </Alert>

                    <Flex direction="row" gap="4" mb="24px">
                        <FormControl isInvalid={!!errors.name} id="name">
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                id="name"
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                placeholder="Name"
                                {...register('name', {
                                    required: 'Required',
                                    validate: trapSpacesForRequiredFields,
                                })}
                                errorBorderColor="red.500"
                            />
                            <FormErrorMessage> {`${errors.name?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                    </Flex>

                    <Flex direction="row" gap="4" mb="24px">
                        <FormControl isInvalid={!!errors.registrationNo}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Registration Number<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                type="text"
                                placeholder="202x/XX/XXX"
                                {...register('registrationNo', {
                                    required: 'Required',
                                    validate: {
                                        trapSpacesForRequiredFields,
                                        // validateIndexNo: () => validateIndexNo(getValues('indexNo'), FieldType.Reg),
                                    },
                                    pattern: {
                                        value: /^(201[6-9]|202[0-1])\/[cCiI][sS]\/[0-2][0-9]{2}$/g,
                                        message: 'Invalid Registration Number',
                                    },
                                })}
                                // onChange={(event) => {
                                //     const inputString = event.target.value;
                                //     let categoryValue: SelectOption;
                                //     if (inputString.includes('2020'))
                                //         categoryValue = CategoryOptions.find((obj) => obj.value === 4);
                                //     else if (inputString.includes('2021'))
                                //         categoryValue = CategoryOptions.find((obj) => obj.value === 3);
                                //     // else categoryValue = CategoryOptions.find((obj) => obj.value === '5');
                                //     setValue('category', categoryValue);
                                //     clearErrors('category');
                                // }}
                            />
                            <FormErrorMessage> {`${errors.registrationNo?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.indexNo}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Index Number<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                fontWeight="500"
                                placeholder="2000xxxx"
                                type="number"
                                {...register('indexNo', {
                                    required: 'Required',
                                    validate: {
                                        trapSpacesForRequiredFields,
                                        validateIndexNo: (value) => validateIndexNo(value, FieldType.Index),
                                    },
                                })}
                            />
                            <FormErrorMessage> {`${errors.indexNo?.message}` || ''}</FormErrorMessage>
                        </FormControl>
                    </Flex>

                    <Flex direction="row" gap="4" mb="24px">
                        <ControlledSelect
                            control={control}
                            name="category"
                            label="Category"
                            focusBorderColor="secondaryGray.100"
                            options={CategoryOptions}
                            placeholder="Select..."
                            selectedOptionStyle="check"
                            chakraStyles={{
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    bg: 'transparent',
                                    px: 2,
                                    cursor: 'inherit',
                                }),
                                placeholder: () => ({
                                    color: 'secondaryGray.600',
                                    fontWeight: '400',
                                    fontSize: 'sm',
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontWeight: '500',
                                    color: 'navy.700',
                                    fontSize: 'sm',
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: 'none',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontWeight: '500',
                                    color: 'navy.700',
                                    fontSize: 'sm',
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    border: '1px solid',
                                    borderColor: 'secondaryGray.100',
                                    borderRadius: '16px',
                                }),
                            }}
                            rules={{
                                required: 'Please select a category.',
                            }}
                        />
                    </Flex>

                    <FormControl isInvalid={!!errors.email} mb="24px">
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            fontWeight="500"
                            type="email"
                            placeholder="mail@simmmple.com"
                            {...register('email', { required: 'Required', validate: trapSpacesForRequiredFields })}
                        />
                        <FormErrorMessage> {`${errors.email?.message}` || ''}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.phone} mb="24px">
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Phone Number<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            fontWeight="500"
                            type="tel"
                            pattern="0[0-9]{9}"
                            placeholder="0741234567"
                            {...register('phone', { required: 'Required', validate: trapSpacesForRequiredFields })}
                        />
                        <FormErrorMessage> {`${errors.phone?.message}` || ''}</FormErrorMessage>
                    </FormControl>

                    <Box>
                        {errorMsg ? (
                            <Alert variant={'left-accent'} status="error" mb={'5'}>
                                <AlertIcon /> {errorMsg}
                            </Alert>
                        ) : null}
                    </Box>
                    <Flex justifyContent="space-between" align="center" direction={'column'} pb="3rem">
                        <Button
                            variant="brand"
                            fontWeight="500"
                            h="50"
                            w="100%"
                            type="submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                        >
                            Register
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default WalkinsRegister;
